
import { defineComponent, onMounted, ref, reactive } from "vue";


export interface Data {

  encountersList: any[];
}


export default defineComponent({
  name: "DevClaims",
  components: {
  },
  
  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      encountersList: [],
    });





    return {
      organizationId,
      data,
    };
  },
});
